<template>
  <div class="content">
    <h1 class="page-title">
      {{ title }}<small class="text-muted">{{ titleEng }}</small>
    </h1>
    <input-basic
      v-model="forms.passport"
      :v="v$.forms.passport"
      label="帳號(Email)"
      col="row mb-3 mt-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.passport.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.password"
      :v="v$.forms.password"
      label="密碼"
      col="row mb-3 mt-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      type="password"
      :maxlength="v$.forms.password.maxLength.$params.max"
      @keyup.enter="clickSubmit"
    />
    <p class="text-center mt-3">
      <button
        type="button"
        class="btn btn-primary"
        :disabled="submitStatus === 'PENDING'"
        @click="clickSubmit"
      >
        <font-awesome-icon icon="check" /> 登入
      </button>
      <button type="button" class="btn btn-link" @click="clickReg">註冊會員</button>
    </p>
  </div>
</template>

<script>
import { AuthenticateAPI } from '@/api/index'
import AxiosErrMsg from '@/helper/AxiosErr.js'
import isValidScroll from '@/helper/useVuelidateScroll.js'

import InputBasic from '@/components/form/input.vue'

import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength, numeric } from '@vuelidate/validators'

export default {
  components: { InputBasic },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      submitStatus: null,
      title: this.$route.meta.nav,
      titleEng: this.$route.meta.navEng,
      send: {},
      forms: {
        passport: '',
        password: ''
      }
    }
  },
  methods: {
    async contentPost(formData) {
      let loader = this.$loading.show()
      await AuthenticateAPI.Check(this.send)
        .then(response => {
          this.$swal({
            title: '會員登入成功',
            icon: 'success'
          })
          this.$store.dispatch('auth/setAuth', {
            passport: response.data.passport,
            token: response.data.token,
            refreshToken: response.data.refreshToken
          })
          this.$router.push({ name: 'Home' })
        })
        .catch(err => {
          AxiosErrMsg(err, this.$swal)
        })
      loader.hide()
      this.submitStatus = null
    },
    clickSubmit() {
      this.v$.$touch()
      if (this.v$.$error) {
        isValidScroll(this)
        return
      }
      this.submitStatus = 'PENDING'
      this.send = JSON.parse(JSON.stringify(this.forms))
      this.contentPost()
    },
    clickReg() {
      this.$router.push({ name: 'register' })
    }
  },
  validations() {
    return {
      forms: {
        passport: { required, email, maxLength: maxLength(50) },
        password: { required, minLength: minLength(8), maxLength: maxLength(24) }
      }
    }
  }
}
</script>

<style></style>
